<template>
	<div v-if="dataInfo.name" class="design-detail" v-title="dataInfo.name + '——千家美装饰室内装修公司_房屋装修设计_家装设计报价'">
		<!-- 头部导航栏 -->
		<NavBar></NavBar>
        <div class="box">
		    <img class="bran" src="../image/design/header_pc.jpg" alt="" />
        </div>
        <div class="box-mob">
            <img
				class="bran"
				src="../image/design/header_mob.jpg"
				alt=""
			/>
		</div>
        <div class="content">
			<div class="left f-y-s-c">
                <div class="info f-x-b-s">
                    <div class="avatar f-x-c-e" :style="` background:center/cover url(${dataInfo.headerImgUrl})`">
                        <div class="text1 f-x-c-c">最近三十天已预约 {{dataInfo.countView}} 人次</div>
                    </div>
                    <div class="msg f-y-b-s">
                        <div class="header f-x-b-c">
                            <span class="text2">{{dataInfo.name}}</span>
                            <div class="ranks2 f-x-c-c">{{ dataInfo.ranks && formartInfo('ranks',dataInfo.ranks)}}</div>
                            <div @click.stop="openDialog" class="btn f-x-c-c">找TA设计</div>
                        </div>
                        <div class="ranks f-x-c-c">{{ dataInfo.ranks && formartInfo('ranks',dataInfo.ranks)}}</div>
                        <div class="box1 f-y-s-s">
                            <div class="item f-x-s-c">
                                <span class="lab">所在门店：</span>
                                <span class="val">{{dataInfo.showRoomNo && formartInfo('store',dataInfo.showRoomNo)}}</span>
                                <span class="cli " @click="novToMap(dataInfo.showRoomNo)">[查看地址]</span>
                            </div>
                            <div class="item f-x-s-s">
                                <span class="lab">从业经验：</span>
                                <span class="val">{{dataInfo.workingLife}}年</span>
                            </div>
                            <div class="item f-x-s-s">
                                <span class="lab">设计理念：</span>
                                <span class="val1">{{dataInfo.designConcept}}</span>
                            </div>
                            <div class="item f-x-s-s">
                                <span class="lab">擅长风格：</span>
                                <span class="val">{{dataInfo.designStyle}}</span>
                            </div>
                            <div class="item f-x-s-s">
                                <span class="lab">代表作品：</span>
                                <span class="val">{{dataInfo.opuses}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="caseList.length" class="about f-y-s-c">
                    <div class="title f-x-s-c">
                        <span class="text1">相关案例</span>
                        <div class="cus-line"></div>
                    </div>
                    <div class="list">
                        <div 
                            v-for="(item,index) in caseList"
                            :key="index"
                            @click="navToCaseDetail(item.id)"
                            class="item f-y-s-s">
                            <div class="img-box">
                                <img class="img" :src="item.coverUrl" alt="">
                            </div>
                            <span class="text2">{{item.villageName}}</span>
                            <div class="info">
                                <HouseInfo type="row" :data="item" textColor="#444444" :textSize="24"></HouseInfo>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="activity">
                    <Activity ></Activity>
                </div>
            </div>
            <div class="right">
               <div class="from">
                    <VisitorForm adUnitCode="nzQJ"></VisitorForm>
               </div>
               <div v-if="recommendList.length" class="recommend" ref="refRecommend">
                    <span class="text4">推荐设计师</span>
                    <div
                        v-for="(item,index) in recommendList.slice(0,3)"
                        :key="index" 
                        :class="index==0? 'no-border':''"
                        :style="`{border-top:}`"
                        class="recommend-item"
                        @click="navToDetail(item.id)"
                        >
                        <img class="img" :src="item.headerImgUrl" alt="">
                        <div class="text1 f-x-c-c">已预约{{item.countView}}人次</div>
                        <div class="box1 f-x-c-c">
                            <div class="mask"></div>
                            <span class="text2">{{item.name}}</span>
                            <span class="text3">{{formartInfo('ranks',item.ranks)}}</span>
                        </div>
                    </div>
               </div>
               <transition name="el-fade-in"  mode="out-in">
                    <div v-if="showFixedForm" class="from-fixed">
                        <VisitorForm adUnitCode="nzQJ"></VisitorForm>
                    </div>
                </transition>
            </div>
		</div>
		<!-- 底部 -->
		<Footer></Footer>
        <!-- 右浮窗和底部吸顶 -->
        <Floating></Floating>
        <FloatingMob></FloatingMob>
        <VisitorForm3></VisitorForm3>
        <Tabbar></Tabbar>

        <DialogForm ref="refDialogForm" adUnitCode="tqDB"></DialogForm>
	</div>
</template>
<script>
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import AboutNav from '../components/AboutNav.vue'
import HouseInfo from '../components/HouseInfo.vue'
import Activity from '../components/Activity.vue'
import VisitorForm from '../components/VisitorForm.vue'
import {codeToText} from 'element-china-area-data'
import { getDesDetail } from '@/api/design.js'
import { getCaseData } from '@/api/case.js'
import { useSalesRoomDict,useDesDict,useHouseAreasDict,useHousetypeDict,useDesStyleDict,useDesRankDict } from '@/utils/frequent.js'
export default {
	components: {
		NavBar,
		Footer,
		AboutNav,
        HouseInfo,
        Activity,
        VisitorForm
	},
	data() {
		return {
            codeToText,
            showDiaInd:-1,
            total:0,
            params:{
                size:3,
                current:1, 
                salesRoomNo:'',
                houseArea:'',
                designStyle:'',
                houseType:''
            },
            ranksList:[],
            storeList:[],
            recommendList:[],
            curId:'',
            dataInfo:{},
            caseList:[],
            showFixedForm:false,
            desList:[]
		}
	},
    watch: {
        // $route(to, from) {
        //     //监听相同路由下参数变化的时候，从而实现异步刷新
        //     this.loading = true;
        //     //重新获取数据
        //     this.getProduct();
        // }
    },
    async mounted(){

        this.curId = this.$route.query.id
        this.ranksList = await useDesRankDict()
        this.storeList = await useSalesRoomDict()

        if(this.curCityInfo.value=='00'){
            this.desList = (await useDesDict()).filter(item=>item.isHeadOffice ==1)
        }else{
            this.desList = (await useDesDict()).filter(item=>item.areaCode ==this.curCityInfo.value)
        }

        // this.desList = await useDesDict()

        this.getDetailData()
        this.getCaseList()
        window.addEventListener("scroll", this.scrollToTop);
    },
	methods: {
         novToMap(showRoomNo){
            const address = this.formartAddress(showRoomNo) 
            // 构建完整的URL，包含搜索参数
            const url = `http://api.map.baidu.com/geocoder?address=${address}&output=html&src=webapp.baidu.openAPIdemo `;
            // 在当前窗口打开该URL
            window.open(url, "_blank");
        },
        formartAddress(showRoomNo){
            let data = {}
            let str
            const res = this.storeList.find(item=>item.showRoomNo==showRoomNo)
            if(res) data = res
            if(data.province) str = codeToText[data.province.slice(0,2)]
            if(data.city) str = str + codeToText[data.city.slice(0,4)]
            if(data.region) str = str + codeToText[data.region]
            if(data.address) str = str + data.address
            return str
        },
        scrollToTop(){
            const scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;

            let height = 1210 
            if(this.$refs.refRecommend){
                const recHeight =  this.$refs.refRecommend.clientHeight
                height += recHeight
            }
            if (scrollTop >= height) {
                this.showFixedForm = true;
            } else  {
                this.showFixedForm = false;
            }
        },
        openDialog(){
            this.$refs.refDialogForm.showDialog('design')
        },
        navToCaseDetail(id){
            this.$router.push({
                path:'/investor/detail',
                query:{
                    id
                }
            })
        },
        navToDetail(id){
            this.$router.push({
                path:'/design/detail',
                query:{
                    id
                }
            })
        },
        async getDetailData(){
            try{
                const res = await getDesDetail(this.curId)
                if(res.code==0){
                    this.dataInfo = res.data
                    this.recommendList = this.desList.filter(item=>{
                        if(item.id!=this.curId && (item.showRoomNo ==this.dataInfo.showRoomNo)){
                            return true
                        }
                    })
                }
            }catch(err){
                console.log(err);
            }
        },
        async getCaseList(){
            try{
                const params = {
                    size:6,
                    current:1,
                    designerId:this.curId
                }
                const res = await getCaseData(params)
                if(res.code==0){
                    this.caseList = res.data.records
                }else this.caseList = []
            }catch(err){
                console.log(err);
            }
        },
        formartInfo(type,data){
            if(type=='ranks'){
                const res = this.ranksList.find(item=>item.value==data)
                if(res) return res.label
                else return data
            }
            if(type=='store'){
                const res = this.storeList.find(item=>item.showRoomNo==data)
                if(res) return res.showRoomName
                else return data
            }
        },
        callback(data){
            const {roomNo,houseArea,designStyle,houseType} = data
            if(roomNo=='-1') delete this.params.houseArea
            else  this.params.salesRoomNo = roomNo

            if(houseArea=='-1') delete this.params.houseArea
            else  this.params.houseArea = houseArea
            
            if(designStyle=='-1') delete this.params.designStyle
            else this.params.designStyle = designStyle

            if(houseType=='-1') delete this.params.houseType
            else this.params.houseType = houseType
            this.params.current = 1
            this.getCaseList()
        }

    },
    beforeDestroy(){
        window.removeEventListener("scroll",this.scrollToTop)
    }
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .design-detail {
        overflow-x: hidden;
		.box {
            display: none;
			
		}
        .box-mob{
            width: 100%;
			height: 160px;
			margin-top: 43px;
			.bran {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
        }
        .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-flow: column;
            padding: 0 12px 30px 12px;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #ffffff;
			
            .left{
                width: 100%;
                margin-top: 50px;
                .info{
                    width: 100%;
                    // height: 220px;
                    flex-flow: column;
                    .avatar{
                        width: 100%;
                        height: 300px;
                        // background: #FFFFFF;
                        // box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.16);
                        border-radius: 5px;
                        // background-size: auto 100% !important;
                        // background-repeat: no-repeat !important;
                        // background-position: center !important;
                        margin-right: 10px;
                        overflow: hidden;
                        .text1{
                            width: 100%;
                            height: 25px;
                            background:rgba(0,0,0,0.2);
                            border-radius:  0 0 5px 5px;
                            
                            font-weight: 400;
                            font-size: 10px;
                            color: #FFFFFF;
                            line-height: 12px;
                        }
                    }
                    .msg{
                        padding: 0 5px;
                        margin-top: 15px;
                        // padding: 5px 0;
                        flex: 1;
                        height: 100%;
                        box-sizing: border-box;
                        .header{
                            width: 100%;
                            .text2{
                                
                                font-weight: bold;
                                font-size: 20px;
                                color: #241934;
                                line-height: 20px;
                            }
                            .btn{
                                cursor: pointer;
                                width: 80px;
                                height: 30px;
                                background: #753CBE;
                                border-radius: 2px;
                                
                                font-weight: 400;
                                font-size: 12px;
                                color: #FFFFFF;
                            }
                            .ranks2{
                                margin-right: 100px;
                                width: 80px;
                                height: 20px;
                                background: #F1F1F1;
                                border-radius: 12px;
                                
                                font-weight: 400;
                                font-size: 12px;
                                color: #241934;
                            }
                        }
                        .ranks{
                            display: none;
                        }
                        .box1{
                            margin-top: 10px;
                            .item{
                                margin-bottom: 5px;
                                .cli{
                                    margin-left: 8px;
                                    
                                    font-weight: 400;
                                    font-size: 10px;
                                    color: #999999;
                                    line-height: 10px;
                                }
                                .lab{
                                    
                                    font-weight: 400;
                                    font-size: 10px;
                                    color: #444444;
                                    line-height: 14px;
                                    min-width: 50px;
                                }
                                .val1{
                                    
                                    font-weight: 400;
                                    font-size: 10px;
                                    color: #444444;
                                    line-height: 14px;

                                    // overflow:hidden;
                                    // text-overflow: ellipsis;
                                    // -webkit-line-clamp: 3;
                                    // display: -webkit-box;
                                    // -webkit-box-orient: vertical;
                                }
                                .val{
                                    
                                    font-weight: 400;
                                    font-size: 10px;
                                    color: #444444;
                                    line-height: 14px;
                                    // text-overflow: ellipsis;
                                    // -webkit-line-clamp: 2;
                                    // display: -webkit-box;
                                    // -webkit-box-orient: vertical;
                                    // overflow: hidden;
                                }
                            }
                        }
                    }
                        
                }
                .about{
                    display: none;
                    width: 100%;
                    margin-top: 50px;
                    .title{
                        width: 100%;
                        .text1{
                            
                            font-weight: bold;
                            font-size: 36px;
                            color: #241934;
                            line-height: 36px;
                            margin-right: 20px;
                        }
                        .cus-line{
                            flex: 1;
                            height: 2px;
                            background: #E5E5E5;
                        }
                    }
                    .list{
                        margin-top: 33px;
                        width: 100%;
                        display: grid;
                        justify-content: space-between;
                        grid-template-columns: repeat(auto-fill,486px);
                        gap: 0 10px;
                        .item{
                            margin-bottom: 40px;
                            width: 486px;
                            height:500px ;
                            box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.11);
                            border-radius: 18px;
                            overflow: hidden;
                            .img-box{
                                width: 100%;
                                height:360px ;
                                overflow: hidden;
                                .img{
                                    width: 100%;
                                    height:100%;
                                }
                            }
                            
                            .text2{
                                padding: 0 27px;
                                margin-top: 26px;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                
                                font-weight: bold;
                                font-size: 36px;
                                color: #241934;
                                line-height: 36px;
                                box-sizing: border-box;
                                width: 100%;
                            }
                            .info{
                                padding: 0 27px;
                                margin-top: 16px;
                                width: 100%;
                                box-sizing: border-box;
                            }
                        }
                    }
                }
                .activity{
                    margin-top: 50px;
                    width: 100%;
                }
            }
            .right{
                width: 100%;
                // height: 800px;
                margin-top: 50px;
                .from{
                    // padding: 0 40px;
                    box-sizing: border-box;
                    width: 100%;
                }
                .from-fixed{
                    display: none;
                }
                .recommend{
                    display: flex;
                    flex-flow: column;
                    width: 100%;
                    background: #FFFFFF;
                    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.16);
                    border-radius: 5px;
                    margin-top: 50px;
                    padding: 0 10px;
                    box-sizing: border-box;
                    .text4{
                        
                        font-weight: bold;
                        font-size: 18px;
                        color: #140626;
                        line-height: 18px;
                        margin: 15px auto 0px auto;
                    }
                    .no-border{
                        border: none !important;
                    }
                    .recommend-item{
                        padding: 20px 0;
                        box-sizing: border-box;
                        width: 100%;
                        // height: 250px;
                        position: relative;
                        border-top: 1px solid #E5E5E5;
                        .img{
                            width: 100%;
                            height: 250px;
                            border-radius: 2px;
                            object-fit: cover;
                        }
                        .text1{
                            right: 0;
                            top: 40px;
                            position: absolute;
                            // width: 80px;
                            padding: 0 10px;
                            height: 25px;
                            background:rgba(117, 60, 190, 0.9);
                            border-radius: 15px 0 0 15px;

                            
                            font-weight: 400;
                            font-size: 10px;
                            color: #FFFFFF;
                            line-height: 25px;
                        }
                        .box1{
                            width: 100%;
                            position: absolute;
                            bottom: 24px;
                            height: 40px;
                            .mask{
                                width: 100%;
                                height: 100%;
                                background: rgba(0,0,0,0.11);
                                position: absolute;
                                left: 0;
                                top: 0;
                                border-radius:0 0 2px 2px;
                            }
                            .text2{
                                z-index: 9;
                                
                                font-weight: bold;
                                font-size: 20px;
                                color: #FFFFFF;
                                margin-right: 10px;
                            }
                            .text3{
                                z-index: 9;
                                
                                font-weight: 400;
                                font-size: 16px;
                                color: #FFFFFF;
                                margin-top: 2px;
                            }
                        }
                    }
                }
            }
           
		}
    }
}
@media screen and (min-width: 900px) and(max-width:1359px) {
	.design-detail {
		height: 100%;
        .box{
            width: 100%;
			height: 230px;
			// margin-top: 96px;
        }
        .box-mob{
            display: none;
        }
		.bran {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.content {
            display: flex;
            justify-content: space-between;
			padding: 0 2% 60px 2%;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: stretch;
			justify-content: center;
			background: #ffffff;
			
            .left{
                // width: 1020px;
                flex: 1;
                margin-right: 67px;
                margin-top: 60px;
                .info{
                    width: 100%;
                    .avatar{
                        width: 420px;
                        height: 510px;
                        background: #FFFFFF;
                        // box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.16);
                        border-radius: 10px;
                       
                        margin-right: 40px;
                        overflow: hidden;
                        .text1{
                            width: 100%;
                            height: 52px;
                            background:rgba(0,0,0,0.2);
                            border-radius:  0 0 10px 10px;

                            
                            font-weight: 400;
                            font-size: 24px;
                            color: #FFFFFF;
                            line-height: 24px;
                        }
                    }
                    .msg{
                        // padding: 13px 0;
                        flex: 1;
                        .header{
                            width: 100%;
                            .text2{
                                
                                font-weight: bold;
                                font-size: 54px;
                                color: #241934;
                                line-height: 54px;
                            }
                            .ranks2{
                                display: none;
                            }
                            .btn{
                                cursor: pointer;
                                width: 230px;
                                height: 80px;
                                background: #753CBE;
                                border-radius: 4px;
                                
                                font-weight: 400;
                                font-size: 30px;
                                color: #FFFFFF;
                            }
                        }
                        .ranks{
                            margin-top: 7px;
                            width: 164px;
                            height: 48px;
                            background: #F1F1F1;
                            border-radius: 24px;
                            
                            font-weight: 400;
                            font-size: 24px;
                            color: #241934;
                        }
                        .box1{
                            margin-top: 30px;
                            .item{
                                margin-bottom: 23px;
                                .cli{
                                    margin-left: 16px;
                                    
                                    font-weight: 400;
                                    font-size: 18px;
                                    color: #999999;
                                    line-height: 18px;
                                }
                                .lab{
                                    
                                    font-weight: 400;
                                    font-size: 22px;
                                    color: #444444;
                                    line-height: 30px;
                                    min-width: 110px;
                                }
                                .val1{
                                    
                                    font-weight: 400;
                                    font-size: 22px;
                                    color: #444444;
                                    line-height: 30px;

                                    // overflow:hidden;
                                    // text-overflow: ellipsis;
                                    // -webkit-line-clamp: 3;
                                    // display: -webkit-box;
                                    // -webkit-box-orient: vertical;
                                }
                                .val{
                                    
                                    font-weight: 400;
                                    font-size: 22px;
                                    color: #444444;
                                    line-height: 30px;
                                    // text-overflow: ellipsis;
                                    // -webkit-line-clamp: 2;
                                    // display: -webkit-box;
                                    // -webkit-box-orient: vertical;
                                    // overflow: hidden;
                                }
                            }
                        }
                    }
                        
                }
                .about{
                    width: 100%;
                    margin-top: 60px;
                    .title{
                        width: 100%;
                        .text1{
                            
                            font-weight: bold;
                            font-size: 36px;
                            color: #241934;
                            line-height: 36px;
                            margin-right: 20px;
                        }
                        .cus-line{
                            flex: 1;
                            height: 2px;
                            background: #E5E5E5;
                        }
                    }
                    .list{
                        margin-top: 33px;
                        width: 100%;
                        display: grid;
                        justify-content: space-between;
                        grid-template-columns: repeat(auto-fill,486px);
                        gap: 0 10px;
                        .item:hover{
                            .img{
                                transform: scale(1.2);
                            }
                        }
                        .item{
                            margin-bottom: 40px;
                            width: 486px;
                            height:500px ;
                            box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.11);
                            border-radius: 18px;
                            overflow: hidden;
                            .img-box{
                                width: 100%;
                                height:360px ;
                                overflow: hidden;
                                cursor: pointer;
                                .img{
                                    transition: all .6s;
                                    width: 100%;
                                    height:100%;
                                    object-fit: cover;
                                }
                            }
                            
                            .text2{
                                cursor: pointer;
                                padding: 0 27px;
                                margin-top: 26px;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                
                                font-weight: bold;
                                font-size: 36px;
                                color: #241934;
                                line-height: 36px;
                                box-sizing: border-box;
                                width: 100%;
                            }
                            .info{
                                padding: 0 27px;
                                margin-top: 16px;
                                width: 100%;
                                box-sizing: border-box;
                            }
                        }
                    }
                }
                .activity{
                    margin-top: 20px;
                    width: 100%;
                }
            }
            .right{
                width: 469px;
                // height: 800px;
                margin-top: 60px;
                .from{
                    width: 100%;
                }
                .from-fixed{
                    width: 100%;
					// margin-top: 60px;
                    position: sticky;
                    top: 30px;
                }
                .recommend{
                    display: flex;
                    flex-flow: column;
                    width: 100%;
                    background: #FFFFFF;
                    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.16);
                    border-radius: 10px;
                    margin-top: 60px;
                    padding: 0 30px;
                    box-sizing: border-box;
                    .text4{
                        
                        font-weight: bold;
                        font-size: 36px;
                        color: #140626;
                        line-height: 36px;
                        margin: 30px auto 0px auto;
                    }
                    .no-border{
                        border: none !important;
                    }
                    .recommend-item{
                        padding: 40px 0;
                        box-sizing: border-box;
                        width: 100%;
                        height: 490px;
                        position: relative;
                        border-top: 1px solid #E5E5E5;
                        .img{
                            width: 410px;
                            height: 410px;
                            border-radius: 4px;
                        }
                        .text1{
                            right: 0;
                            top: 60px;
                            position: absolute;
                            width: 158px;
                            height: 50px;
                            background:rgba(117, 60, 190, 0.9);
                            border-radius: 25px 0 0 25px;

                            
                            font-weight: 400;
                            font-size: 20px;
                            color: #FFFFFF;
                            line-height: 20px;
                        }
                        .box1{
                            width: 100%;
                            height: 60px;
                            bottom: 40px;
                            position: absolute;
                            .mask{
                                width: 100%;
                                height: 100%;
                                background: rgba(0,0,0,0.11);
                                position: absolute;
                                left: 0;
                                top: 0;
                                border-radius:0 0 4px 4px;
                            }
                            .text2{
                                z-index: 9;
                                
                                font-weight: bold;
                                font-size: 36px;
                                color: #FFFFFF;
                                margin-right: 23px;
                            }
                            .text3{
                                z-index: 9;
                                
                                font-weight: 400;
                                font-size: 24px;
                                color: #FFFFFF;
                            }
                        }
                    }
                }
            }
           
		}
	}
}
@media screen and (min-width: 1360px) {
	.design-detail {
		height: 100%;
        .box{
            width: 100%;
			height: 230px;
			// margin-top: 96px;
        }
        .box-mob{
            display: none;
        }
		.bran {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.content {
            display: flex;
            justify-content: space-between;
			padding: 0 180px 60px 180px;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: stretch;
			justify-content: center;
			background: #ffffff;
			
            .left{
                // width: 1020px;
                flex: 1;
                margin-right: 67px;
                margin-top: 60px;
                .info{
                    width: 100%;
                    .avatar{
                        width: 420px;
                        height: 510px;
                        background: #FFFFFF;
                        // box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.16);
                        border-radius: 10px;
                       
                        margin-right: 40px;
                        overflow: hidden;
                        .text1{
                            width: 100%;
                            height: 52px;
                            background:rgba(0,0,0,0.2);
                            border-radius:  0 0 10px 10px;

                            
                            font-weight: 400;
                            font-size: 24px;
                            color: #FFFFFF;
                            line-height: 24px;
                        }
                    }
                    .msg{
                        // padding: 13px 0;
                        flex: 1;
                        .header{
                            width: 100%;
                            .text2{
                                
                                font-weight: bold;
                                font-size: 54px;
                                color: #241934;
                                line-height: 54px;
                            }
                            .ranks2{
                                display: none;
                            }
                            .btn{
                                cursor: pointer;
                                width: 230px;
                                height: 80px;
                                background: #753CBE;
                                border-radius: 4px;
                                
                                font-weight: 400;
                                font-size: 30px;
                                color: #FFFFFF;
                            }
                        }
                        .ranks{
                            margin-top: 7px;
                            width: 164px;
                            height: 48px;
                            background: #F1F1F1;
                            border-radius: 24px;
                            
                            font-weight: 400;
                            font-size: 24px;
                            color: #241934;
                        }
                        .box1{
                            margin-top: 30px;
                            .item{
                                margin-bottom: 23px;
                                .cli{
                                    margin-left: 16px;
                                    cursor: pointer;
                                    font-weight: 400;
                                    font-size: 18px;
                                    color: #999999;
                                    line-height: 18px;
                                }
                                .lab{
                                    
                                    font-weight: 400;
                                    font-size: 22px;
                                    color: #444444;
                                    line-height: 30px;
                                    min-width: 110px;
                                }
                                .val1{
                                    
                                    font-weight: 400;
                                    font-size: 22px;
                                    color: #444444;
                                    line-height: 30px;

                                    // overflow:hidden;
                                    // text-overflow: ellipsis;
                                    // -webkit-line-clamp: 3;
                                    // display: -webkit-box;
                                    // -webkit-box-orient: vertical;
                                }
                                .val{
                                    
                                    font-weight: 400;
                                    font-size: 22px;
                                    color: #444444;
                                    line-height: 30px;
                                    // text-overflow: ellipsis;
                                    // -webkit-line-clamp: 2;
                                    // display: -webkit-box;
                                    // -webkit-box-orient: vertical;
                                    // overflow: hidden;
                                }
                            }
                        }
                    }
                        
                }
                .about{
                    width: 100%;
                    margin-top: 60px;
                    .title{
                        width: 100%;
                        .text1{
                            
                            font-weight: bold;
                            font-size: 36px;
                            color: #241934;
                            line-height: 36px;
                            margin-right: 20px;
                        }
                        .cus-line{
                            flex: 1;
                            height: 2px;
                            background: #E5E5E5;
                        }
                    }
                    .list{
                        margin-top: 33px;
                        width: 100%;
                        display: grid;
                        justify-content: space-between;
                        grid-template-columns: repeat(auto-fill,486px);
                        gap: 0 10px;
                        .item:hover{
                            .img{
                                transform: scale(1.2);
                            }
                        }
                        .item{
                            margin-bottom: 40px;
                            width: 486px;
                            height:500px ;
                            box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.11);
                            border-radius: 18px;
                            overflow: hidden;
                            .img-box{
                                width: 100%;
                                height:360px ;
                                overflow: hidden;
                                cursor: pointer;
                                .img{
                                    transition: all .6s;
                                    width: 100%;
                                    height:100%;
                                    object-fit: cover;
                                }
                            }
                            
                            .text2{
                                cursor: pointer;
                                padding: 0 27px;
                                margin-top: 26px;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                
                                font-weight: bold;
                                font-size: 36px;
                                color: #241934;
                                line-height: 36px;
                                box-sizing: border-box;
                                width: 100%;
                            }
                            .info{
                                padding: 0 27px;
                                margin-top: 16px;
                                width: 100%;
                                box-sizing: border-box;
                            }
                        }
                    }
                }
                .activity{
                    margin-top: 20px;
                    width: 100%;
                }
            }
            .right{
                width: 469px;
                // height: 800px;
                margin-top: 60px;
                .from{
                    width: 100%;
                }
                .from-fixed{
                    width: 100%;
					// margin-top: 60px;
                    position: sticky;
                    top: 30px;
                }
                .recommend{
                    display: flex;
                    flex-flow: column;
                    width: 100%;
                    background: #FFFFFF;
                    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.16);
                    border-radius: 10px;
                    margin-top: 60px;
                    padding: 0 30px;
                    box-sizing: border-box;
                    .text4{
                        
                        font-weight: bold;
                        font-size: 36px;
                        color: #140626;
                        line-height: 36px;
                        margin: 30px auto 0px auto;
                    }
                    .no-border{
                        border: none !important;
                    }
                    .recommend-item{
                        padding: 40px 0;
                        box-sizing: border-box;
                        width: 100%;
                        height: 490px;
                        position: relative;
                        border-top: 1px solid #E5E5E5;
                        .img{
                            width: 410px;
                            height: 410px;
                            border-radius: 4px;
                        }
                        .text1{
                            right: 0;
                            top: 60px;
                            position: absolute;
                            width: 158px;
                            height: 50px;
                            background:rgba(117, 60, 190, 0.9);
                            border-radius: 25px 0 0 25px;

                            
                            font-weight: 400;
                            font-size: 20px;
                            color: #FFFFFF;
                            line-height: 20px;
                        }
                        .box1{
                            width: 100%;
                            height: 60px;
                            bottom: 40px;
                            position: absolute;
                            .mask{
                                width: 100%;
                                height: 100%;
                                background: rgba(0,0,0,0.11);
                                position: absolute;
                                left: 0;
                                top: 0;
                                border-radius:0 0 4px 4px;
                            }
                            .text2{
                                z-index: 9;
                                
                                font-weight: bold;
                                font-size: 36px;
                                color: #FFFFFF;
                                margin-right: 23px;
                            }
                            .text3{
                                z-index: 9;
                                
                                font-weight: 400;
                                font-size: 24px;
                                color: #FFFFFF;
                            }
                        }
                    }
                }
            }
           
		}
	}
}
</style>
